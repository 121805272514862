
import { Configuration, DocumentLegacyControllerApi, FindDocumentsFilteredUsingGETSourceTypesEnum } from 'api/document';
import DEFAULT_DATA from 'lib/data';
import {
  useContext,
  useEffect,
  useState,
} from 'react';
import { AuthContext } from 'contexts/AuthContext';
import { showNotification } from 'lib/Notification';
import { LanguageContext } from 'contexts/LanguageContext';
import { Modal } from 'antd';
import { useHistory } from 'react-router';
import { getErrorCodeFromURI } from 'lib/ErrorCodesUtils';
import { Watermark } from '../interfaces';
import { translations } from './translations';

export function useWatermarks() {
  const { tl } = useContext(LanguageContext);
  const history = useHistory();
  const [watermarks, setWatermarks] = useState(DEFAULT_DATA<Watermark[]>([]));

  const { documentApiConfiguration } = useContext(AuthContext);
  const documentControllerApi = new DocumentLegacyControllerApi(documentApiConfiguration('document') as unknown as Configuration);

  const onLoadWatermarks = async (resetPage: boolean = false) => {
    setWatermarks(watermarks.startLoading());

    const filter = {
      sourceTypes: FindDocumentsFilteredUsingGETSourceTypesEnum.WATERMARK,
    };

    try {
      const response = await documentControllerApi.findDocumentsFilteredUsingGET(filter);
      setWatermarks(prev => prev.loadPaged(response.content, resetPage, response.last));
    } catch (err) {
      console.error(err);
      showNotification({
        key: 'getWatermarkError',
        message: tl(translations.error.getWatermarksError.message),
        description: tl(translations.error.getWatermarksError.description),
        type: 'error',
      });
    }
  };

  const onDeleteWatermark = async (watermarkId: number, onConfirm: () => void = null) => {
    Modal.confirm({
      title: tl(translations.confirmModal.deleteConfirm.confirmationTitle),
      content: tl(translations.confirmModal.deleteConfirm.confirmationText),
      okText: tl(translations.confirmModal.delete),
      cancelText: tl(translations.confirmModal.cancel),
      onOk: async () => {
        const documentIds = [watermarkId];

        try {
          await documentControllerApi.deleteDocumentUsingDELETE({ documentIds });
          showNotification({
            key: 'saveWatermarkSuccess',
            message: tl(translations.successNotification.deleteWatermark),
            type: 'success',
          });

          onLoadWatermarks(true);
          onConfirm?.();
        } catch (err) {
          console.error(err);
          err.response.json().then((errorBody) => {
            const errorCodeURI = errorBody?.type;
            const errorCode = getErrorCodeFromURI(errorCodeURI);
            if (errorCode) {
              showNotification({
                key: 'deleteWatermarkError',
                message: tl(translations.error.deleteWatermarkError.message.generic),
                description: tl(translations.error.deleteWatermarkError.description[errorCode]),
                type: 'error',
              });
            } else {
              showGenericDeleteErrorNotification();
            }
          }).catch(() => showGenericDeleteErrorNotification());
        }
      },
      okButtonProps: { className: 'Button' },
      cancelButtonProps: { className: 'Button' },
      icon: false,
      width: 450,
    });
  };

  const showGenericDeleteErrorNotification = () => {
    showNotification({
      key: 'deleteWatermarkError',
      message: tl(translations.error.deleteWatermarkError.message.generic),
      description: tl(translations.error.deleteWatermarkError.description.generic),
      type: 'error',
    });
  };

  useEffect(() => {
    onLoadWatermarks(true);
    // Force watermark reload when history changes (Ex: from /watermarks/create -> /watermarks)
  }, [history.location.pathname]);

  return {
    onLoadWatermarks,
    onDeleteWatermark,
    watermarks,
  };
}
