import {
  Context,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  ContactLegacyControllerApi,
  ContactLegacyDto,
  PropertyLegacyControllerApi,
  PropertyLegacyDto,
} from 'api/accounting';
import {
  Configuration,
  CreateSyncDataDtoSourceTypeEnum,
  CreateSyncDataDtoStateEnum,
  CreateSyncDataUsingPOSTResourceNameEnum,
  DeleteSyncDataUsingDELETEResourceNameEnum,
  GetSyncDataUsingGETResourceNameEnum,
  GetSyncDataUsingGETSourceTypeEnum,
  SyncDataApi,
  SyncDataDto,
} from 'api/app';
import { CasaviAPI } from 'api/casavi/casaviApi';
import { CasaviTicket } from 'api/casavi/interfaces';
import { DocumentApi } from 'api/public';
import { AuthContext } from 'contexts/AuthContext';
import { LanguageContext } from 'contexts/LanguageContext';
import { getEnvVar } from 'lib/getEnvVar';
import { showNotification } from 'lib/Notification';
import _, { debounce } from 'lodash';
import { getCasaviToken } from 'services/casavi/getCasaviToken';
import {
  ExternalPropertyIssue,
  PortalDocumentProps,
} from 'storybook-components/TicketIntegrationSection/interfaces';
import {
  calculateRelevance,
} from 'storybook-components/TicketIntegrationSection/services/ticketUtils';

import { translations } from '../../translations';

const casaviUiBaseUrl = getEnvVar('casavi.uiUrl', 'https://mycasavi.com/manage');

const mapTicketToIssue = (ticket):ExternalPropertyIssue => ({
  id: ticket.id,
  type: 'Ticket',
  number: ticket.number,
  state: ticket.status,
  contactName: ticket.contactName,
  name: ticket.title,
  description: ticket.description,
  url: `${casaviUiBaseUrl}/tickets/${ticket.internalId}/`,
  relevanceScore: 1,
  assigned: false,
  searchValue: `${ticket.number} ${ticket.state} ${ticket.contactName} ${ticket.name} ${ticket.description}`.toLocaleLowerCase(),
});

const debouncableMethod = (setSearchTerm, searchTermNewValue) => {
  setSearchTerm(searchTermNewValue);
};

const debouncedSearch = debounce(debouncableMethod, 500);

export const useCasaviTickets = (connectionId: number, { propertyId, contactId }: {propertyId?: number, contactId?: number}, documentProps: PortalDocumentProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [issues, setIssues] = useState<ExternalPropertyIssue[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [casaviId, setCasaviId] = useState<string>();
  const [timestamp, setTimestamp] = useState<Date>();
  const [casaviLink, setCasaviLink] = useState<string>();
  const [syncData, setSyncData] = useState<SyncDataDto>();

  const { apiConfiguration } = useContext(AuthContext);
  const appContext = useContext<{ apiConfiguration:(string)
    => Configuration }>(AuthContext as unknown as Context<{ apiConfiguration: (string) => Configuration }>);
  const { publicApiConfiguration } = useContext(AuthContext);
  const documentApi = new DocumentApi(publicApiConfiguration('public'));

  const { tl } = useContext(LanguageContext);

  const propertyControllerApi = new PropertyLegacyControllerApi(apiConfiguration('accounting'));
  const contactControllerApi = new ContactLegacyControllerApi(apiConfiguration('accounting'));
  const syncDataApi = new SyncDataApi(appContext.apiConfiguration('app'));

  useEffect(() => {
    if (propertyId) {
      getPropertyCasaviId(propertyId);
    }
  }, [propertyId]);

  useEffect(() => {
    if (contactId) {
      getContactCasaviId(contactId);
    }
  }, [contactId]);

  useEffect(() => {
    if (!loading && casaviId && connectionId) {
      getIssues();
    }
  }, [casaviId, connectionId]);

  const issuesWithAssignments = useMemo(() => {
    let assignedIssueId;
    if (syncData?.externalEntityId) {
      assignedIssueId = JSON.parse(syncData?.externalEntityId).orderId;
    }
    return issues.filter(i => !_.isNil(i)).map(issue => ({ ...issue, assigned: issue?.id === assignedIssueId }));
  }, [issues, syncData]);

  const filteredAndRankedIssues = useMemo(() => {
    if (!searchTerm) {
      return [...issuesWithAssignments]
        .sort((a, b) => (Number(b.assigned) - Number(a.assigned)));
    }
    return issuesWithAssignments
      .map(calculateRelevance(searchTerm))
      .filter(e => e.relevanceScore > 0)
      .sort((a, b) => {
        if (a.relevanceScore > b.relevanceScore) {
          return 1;
        } if (a.relevanceScore < b.relevanceScore) {
          return -1;
        }
        return 0;
      });
  }, [issuesWithAssignments, searchTerm]);

  useEffect(() => {
    if (documentProps?.entityId || documentProps?.sourceId) {
      getSyncData();
    }
  }, [connectionId, documentProps?.entityId, documentProps?.sourceId]);

  const onChangeSearchTerm = (searchTermNewValue) => {
    debouncedSearch(setSearchTerm, searchTermNewValue);
  };

  const getPropertyCasaviId = async (pId: number) => {
    try {
      const response: PropertyLegacyDto = await propertyControllerApi.getPropertyByIdUsingGET({ propertyId: pId });
      setCasaviId(response.casaviSyncData?.externalEntityId);
      setTimestamp(response.casaviSyncData.lastSyncDate);
    } catch (e) {
      setErrorMessage(tl(translations.errors.propertyNotFound));
      console.error(e);
    }
  };

  const getContactCasaviId = async (cId: number) => {
    try {
      const response: ContactLegacyDto = await contactControllerApi.getContactUsingGET({ contactId: cId });
      setCasaviId(response.casaviSyncData?.externalEntityId);
      setTimestamp(response.casaviSyncData.lastSyncDate);
    } catch (e) {
      setErrorMessage(tl(translations.errors.contactNotFound));
      console.error(e);
    }
  };

  const getIssues = async () => {
    let allTickets: CasaviTicket[] = [];
    setLoading(true);
    try {
      const token = await getCasaviToken(apiConfiguration, { id: connectionId });
      try {
        const casaviApi = new CasaviAPI({ token });
        // load issues based on contact
        if (contactId) {
          const cc = await casaviApi.getContactById(casaviId);
          setCasaviLink(`${casaviUiBaseUrl}/contacts/${cc?.internalId}/`);
          await Promise.all(cc.contracts.map(async (contract) => {
            allTickets = await casaviApi.getAllTicketsBy({ unitId: contract.unitId });
            setIssues(iss => iss.concat(allTickets.map(mapTicketToIssue)));
          }));
        }
        // load issues based on property
        if (propertyId) {
          const cp = await casaviApi.getPropertyById(casaviId);
          setCasaviLink(`${casaviUiBaseUrl}/communities/${cp?.internalId}/`);
          allTickets = await casaviApi.getAllTicketsBy({ propertyId: casaviId });
          setIssues(allTickets.map(mapTicketToIssue));
        }
      } catch (e) {
        setErrorMessage(tl(translations.errors.communication)('casavi'));
        console.error(e);
      }
    } catch (e) {
      setErrorMessage(tl(translations.errors.credentials));
      console.error(e);
    }
    setLoading(false);
  };

  const isAssigned = useMemo(() => issuesWithAssignments.some(issue => issue.assigned), [issuesWithAssignments]);

  const getSyncData = async () => {
    try {
      const response: SyncDataDto[] = (await syncDataApi.getSyncDataUsingGET({
        connectionId,
        resourceName: GetSyncDataUsingGETResourceNameEnum.documents,
        resourceId: documentProps?.entityId,
        sourceId: documentProps?.entityId ? undefined : documentProps?.sourceId,
        sourceType: documentProps?.entityId ? undefined : documentProps?.sourceType as unknown as GetSyncDataUsingGETSourceTypeEnum,
      })).content;
      setSyncData(response[0]);
    } catch (e) {
      console.error(e);
    }
  };

  const createDocumentSyncData = async (orderId: number, timelineEntryId: number) => {
    try {
      const newSyncData = {
        sourceId: documentProps?.sourceId,
        entityId: documentProps?.entityId,
        sourceType: documentProps?.sourceType as unknown as CreateSyncDataDtoSourceTypeEnum,
        externalEntityId: JSON.stringify({ orderId, timelineEntryId }),
        state: CreateSyncDataDtoStateEnum.SUCCESS,
        syncTimestamp: new Date(),
      };
      const response: SyncDataDto = await syncDataApi.createSyncDataUsingPOST({
        resourceName: CreateSyncDataUsingPOSTResourceNameEnum.documents,
        connectionId,
        createSyncDataDto: newSyncData,
      });
      setSyncData(response);
    } catch (e) {
      console.error(e);
      setErrorMessage(tl(translations.errors.assign));
    }
  };

  const assignTicket = async (issueId: number, comm: string) => {
    setLoading(true);
    try {
      const token = await getCasaviToken(apiConfiguration, { id: connectionId });
      const casaviApi = new CasaviAPI({ token });
      let fileId: number;
      if (documentProps?.entityId) {
        try {
          const file: any = await documentApi.downloadUsingGET(
            { documentId: documentProps?.entityId },
          );
          const blob = new Blob([file], { type: 'application/pdf' });
          const fileResponse = await casaviApi.uploadFile('application/pdf', `${documentProps?.fileName}.pdf`, blob);
          fileId = fileResponse.id;
        } catch (e) {
          showNotification({
            key: 'casaviAssignError',
            message: tl(translations.errors.uploadFail)('casavi'),
            type: 'warning',
          });
          console.error(e);
        }
      }
      try {
        const attachments = fileId ? [fileId] : [];
        const commentResponse = await casaviApi.commentOnTicket(issueId.toString(), comm, attachments);
        createDocumentSyncData(issueId, commentResponse.id);
      } catch (e) {
        console.error(e);
        setLoading(false);
        setErrorMessage(tl(translations.errors.communication)('casavi'));
      }
    } catch (e) {
      setErrorMessage(tl(translations.errors.credentials));
    }
    setLoading(false);
  };

  const unassignTicket = async () => {
    try {
      await syncDataApi.deleteSyncDataUsingDELETE({
        resourceName: DeleteSyncDataUsingDELETEResourceNameEnum.documents,
        connectionId,
        id: syncData.id,
      });
      setSyncData(undefined);
    } catch (e) {
      console.error(e);
      setErrorMessage(tl(translations.errors.unassign));
    }
  };

  return {
    loading,
    errorMessage,
    issues: filteredAndRankedIssues,
    onChangeSearchTerm,
    casaviLink,
    timestamp,
    isAssigned,
    assignTicket,
    unassignTicket,
  };
};
