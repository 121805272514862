import { getEnvVar } from 'lib/getEnvVar';

import {
  Etg24Contract,
  Etg24Process,
  Etg24Property,
  Etg24Signatory,
} from './interfaces';

const etg24BaseURL = getEnvVar('etg24.apiBaseUrl', 'https://api.staging.develop.impower.de/services/impower-etg24/api/v1/proxy');
const etg24ApiURL = getEnvVar('etg24.apiUrl', 'https://api.etg24test.de/propertymanagement/v1');
type HttpMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

const buildFetchOptions = (method: HttpMethod, body?: any): RequestInit => {
  const authString: any = localStorage.getItem('auth');
  const auth: any = JSON.parse(authString);
  const fetchOptions = {
    method,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${auth.token}`,
    },
    credentials: 'include', // include, same-origin, *omit
    mode: 'cors', // no-cors, *same-origin
    redirect: 'follow', // *manual, error
    referrer: 'no-referrer', // *client
  } as RequestInit;
  if (body) {
    fetchOptions.body = JSON.stringify(body);
  }
  return fetchOptions;
};

export const getAllProperties = async (connectionId: number) => {
  const response = await fetch(`${etg24BaseURL}/objects?proxiedConnectionId=${connectionId}`, buildFetchOptions('GET'));
  return response.json() as Promise<Etg24Property[]>;
};

export const getSignatory = async (connectionId: number, signatoryId: string) => {
  const response = await fetch(`${etg24BaseURL}/signatories/${signatoryId}?proxiedConnectionId=${connectionId}`, buildFetchOptions('GET'));
  return response.json() as Promise<Etg24Signatory>;
};

export const getProcessesByContractId = async (connectionId: number, contractId: string) => {
  const response = await fetch(`${etg24BaseURL}/contracts/${contractId}/processes?proxiedConnectionId=${connectionId}`, buildFetchOptions('GET'));
  return response.json() as Promise<Etg24Process[]>;
};

export const getProcessesByObjectId = async (connectionId: number, objectId: string) => {
  const response = await fetch(`${etg24BaseURL}/objects/${objectId}/processes?proxiedConnectionId=${connectionId}`, buildFetchOptions('GET'));
  return response.json() as Promise<Etg24Process[]>;
};

export const postUpdateOnProcess = async (accessToken: string, processId: string, body: FormData) => {
  const response = await fetch(`${etg24ApiURL}/processes/${processId}/updates?access_token=${accessToken}`, { method: 'POST', body });
  return response;
};

export const getContractsByPropertyId = async (connectionId: number, propertyId: number) => {
  const response = await fetch(`${etg24BaseURL}/objects/${propertyId}/contracts?proxiedConnectionId=${connectionId}`, buildFetchOptions('GET'));
  return response.json() as Promise<Etg24Contract[]>;
}
