import {
  Configuration, DocumentLegacyControllerApi, DocumentCreateDto, DocumentCreateDtoSourceTypeEnum, DocumentProjectionDto, DocumentUpdateLegacyDto, DocumentUpdateLegacyDtoAssignedToEnum, DocumentUpdateLegacyDtoDocumentStateEnum, DocumentUpdateLegacyDtoSourceTypeEnum, DocumentUpdateLegacyDtoOcrStateEnum,
} from 'api/document';
import { AuthContext } from 'contexts/AuthContext';
import { LanguageContext } from 'contexts/LanguageContext';
import { getErrorCodeFromURI } from 'lib/ErrorCodesUtils';
import { showNotification } from 'lib/Notification';
import { useWatermarks } from 'pages/Watermarks/services/useWatermarks';
import {
  ChangeEvent, useContext, useEffect, useState,
} from 'react';
import { OverlayContext } from 'services/OverlayContext/OverlayContext';
import { usePdfUtils } from 'services/usePdfUtils';
import { watermarkTranslations } from '../translations';
import { useFeatureSettings } from 'lib/useFeatureSettings';


interface WatermarkState extends DocumentProjectionDto {
  blob?: File,
  base64Url?: string,
}


export default function useWatermarkEditor(watermarkId: number) {
  const { tl } = useContext(LanguageContext);
  const { goBack } = useContext(OverlayContext);
  const [watermark, setWatermark] = useState<WatermarkState>(null);
  const { onDeleteWatermark } = useWatermarks();
  const [isWatermarkDirty, setIsWatermarkDirty] = useState(false);
  const [newFileUploaded, setNewFileUploaded] = useState(false);

  const { documentApiConfiguration } = useContext(AuthContext);
  const { twoPageWatermark } = useFeatureSettings();
  const documentControllerApi = new DocumentLegacyControllerApi(documentApiConfiguration('document') as unknown as Configuration);

  const { getPdfByBase64UrlUtil } = usePdfUtils();


  useEffect(() => {
    if (watermarkId) {
      onLoadWatermark();
    }
  }, [watermarkId]);


  const onLoadWatermark = () => {
    documentControllerApi.getDocumentByIdUsingGET({ documentId: watermarkId })
      .then((resp) => {
        setWatermark(resp);
      })
      .catch((err) => {
        console.error(err);
        showNotification({
          type: 'error',
          message: tl(watermarkTranslations.error.loadWatermarkError),
        });
      });
  };


  const handleWatermarkSelected = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files[0];


    // get base64 url to show the preview without saving file
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      const base64Url = reader.result;

      validateWatermark(base64Url as string)
        .then((isOnlyOnePageLong) => {
          if (isOnlyOnePageLong) {
            // setting URL to null is needed to be able to re-paint the new pdf
            setWatermark(null);
            // intentionally using old state `watermark?.name` to keep the name but reset everything else
            setWatermark({ base64Url: base64Url as string, blob: file, name: watermark?.name || file.name });
            setIsWatermarkDirty(true);
            setNewFileUploaded(true);
          } else {
            showNotification({
              type: 'error',
              message: tl(errorWatermarkMessage),
            });
          }
        })
        .catch((error) => {
          console.error(error);
          showNotification({
            type: 'error',
            message: tl(watermarkTranslations.error.parseError),
          });
        });
    };

    reader.onerror = (error) => {
      console.error(error);
      showNotification({
        type: 'error',
        message: tl(watermarkTranslations.error.parseError),
      });
    };

    const validateIsOnlyOnePageLong = (base64Url: string) => getPdfByBase64UrlUtil(base64Url)
      .then((responsePdf: any) => {
        if (responsePdf._pdfInfo?.numPages === 1) {
          return Promise.resolve(true);
        }
        return Promise.resolve(false);
      });

    const validateIsMaxTwoPageLong = (base64Url: string) => getPdfByBase64UrlUtil(base64Url)
      .then((responsePdf) => {
        const numPages = responsePdf._pdfInfo?.numPages;
        
        if ( numPages === 1 || numPages === 2) {
          return Promise.resolve(true);
        }

        return Promise.resolve(false);
      });

    const validateWatermark = twoPageWatermark
      ? validateIsMaxTwoPageLong
      : validateIsOnlyOnePageLong;

    const errorWatermarkMessage = twoPageWatermark
      ? watermarkTranslations.error.pdfMax2Pages
      : watermarkTranslations.error.pdfTooLong;
  };


  const onUpdateWatermarkName = (updatedName: string) => {
    setWatermark(prev => ({ ...(prev ?? {}), name: updatedName }));
    setIsWatermarkDirty(true);
  };

  const onSaveWatermark = async () => {
    if (!isWatermarkDirty) return;

    try {
      if (watermark?.id === undefined && watermarkId && newFileUploaded) {
        // if we uploaded another file and save then delete old document
        await documentControllerApi.deleteDocumentUsingDELETE({ documentIds: [watermarkId] });
      }

      if (watermark?.id !== undefined) {
        // if we didn't upload another file then update the rest of the fields
        const {
          id, sourceType, ...rest
        } = watermark;
        const documentUpdateDtos: Array<DocumentUpdateLegacyDto> = [];
        documentUpdateDtos.push({
          ...rest,
          id: id!,
          name: rest.name!,
          assignedTo: rest.assignedTo as unknown as DocumentUpdateLegacyDtoAssignedToEnum,
          sourceType: sourceType as unknown as DocumentUpdateLegacyDtoSourceTypeEnum,
          documentState: DocumentUpdateLegacyDtoDocumentStateEnum.READY,
          ocrState: DocumentUpdateLegacyDtoOcrStateEnum.NO_OCR,
        });
        documentControllerApi.updateDocumentsUsingPUT({
          updateDtos: documentUpdateDtos,
        }).then(() => {
          showNotification({
            key: 'saveWatermarkSuccess',
            message: tl(watermarkTranslations.successNotification.saveWatermark),
            type: 'success',
          });
          setIsWatermarkDirty(false);
          setNewFileUploaded(false);
          goBack();
        });
      } else {
        const documentCreateDtos: Array<DocumentCreateDto> = [];
        const documentCreateDto: DocumentCreateDto = {
          name: watermark.name || watermark.blob.name,
          sourceType: DocumentCreateDtoSourceTypeEnum.WATERMARK,
        };
        documentCreateDtos.push(documentCreateDto);
        documentControllerApi.createDocumentsUsingPOST({
          documentCreateDtos,
        }).then((resp) => {
          documentControllerApi.uploadFileForDocumentUsingPUT({
            documentId: resp[0].id,
            file: watermark.blob,
          })
            .then(() => {
              showNotification({
                key: 'saveWatermarkSuccess',
                message: tl(watermarkTranslations.successNotification.saveWatermark),
                type: 'success',
              });
              setIsWatermarkDirty(false);
              setNewFileUploaded(false);
              goBack();
            });
        });
      }
    } catch (err) {
      err.response.json().then((errorBody) => {
        const errorCodeURI = errorBody?.type;
        const errorCode = getErrorCodeFromURI(errorCodeURI);
        if (errorCode) {
          showNotification({
            key: 'deleteWatermarkError',
            message: tl(watermarkTranslations.error.deleteWatermarkError.message),
            description: tl(watermarkTranslations.error.deleteWatermarkError.description[errorCode]),
            type: 'error',
          });
        } else {
          showNotification({
            key: 'saveWatermarkError',
            message: tl(watermarkTranslations.error.saveWatermarkError.message),
            description: tl(watermarkTranslations.error.saveWatermarkError.description),
            type: 'error',
          });
        }
      });
    }
  };

  const onConfirmDeleteWatermark = () => {
    setIsWatermarkDirty(false);
    setNewFileUploaded(false);
    goBack();
  };

  return {
    watermark,
    isWatermarkDirty,
    newFileUploaded,
    handleWatermarkSelected,
    onSaveWatermark,
    onDeleteWatermark: (id: number) => onDeleteWatermark(id, onConfirmDeleteWatermark),
    onUpdateWatermarkName,
  };
}
