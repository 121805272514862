import React, { useContext } from 'react';
import './InvoiceEditingWithPdf.scss';
import ResizableContainer from 'elements/ResizableContainer/ResizableContainer';
import { InvoiceEditingContext } from 'contexts/InvoiceEditingContext';
import SimplePdfViewer from 'elements/PdfViewer/SimplePdfViewer';
import { useOcrProgressPolling } from 'services/useOcrProgressPolling';
import { DocumentProjectionDtoOcrStateEnum, FindDocumentsFilteredUsingGETSourceTypesEnum } from 'api/document';
import { Tag } from 'antd';
import InvoiceEditorForm from '../InvoiceEditorForm/InvoiceEditorForm';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import { translations } from '../translations';
import { ICONS } from 'components/icons';
import Icon from '@ant-design/icons/lib/components/Icon';

export default function InvoiceEditingWithPdf(props: any): JSX.Element {
  const invoiceEditingContext = useContext(InvoiceEditingContext);
  const { tl } = useContext(LanguageContext);

  const { id } = invoiceEditingContext.data?.invoice;
  const { onOcrReady } = invoiceEditingContext;
  const { ocrInProgress, document, customOnLoadCatch } = useOcrProgressPolling({ sourceId: id, sourceType: FindDocumentsFilteredUsingGETSourceTypesEnum.INVOICE, onReadyCallback: onOcrReady });

  const isEinvoice = document?.ocrState === DocumentProjectionDtoOcrStateEnum.EINVOICE;
  return (
    <div className="InvoiceEditingWithPdf">
      <div className="pdf-preview">
        {isEinvoice ? <Tag className="e-invoice-tag" icon={<Icon component={ICONS.eInvoiceDocument} />} color="default" style={{position: 'absolute', left: '50%', top: '20px'}}>E-Rechnung</Tag> : undefined}
        <SimplePdfViewer
          showOcrInProgress={ocrInProgress}
          ocrText={tl(translations.notifications.analyzing)}
          customOnLoadCatch={customOnLoadCatch}
          documentId={document?.id}
          filename={invoiceEditingContext.data.invoice.invoiceHrId}
          renderTextLayer
        />
      </div>
      <ResizableContainer
        className="accordion-container"
        initialWidth={600}
        maxWidth={960}
        minWidth={600}
      >
        <InvoiceEditorForm {...props} viewMode="withPdf" />
      </ResizableContainer>
    </div>
  );
}
