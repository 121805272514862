import React, { ChangeEvent, useRef, useState } from 'react';
import './CurrencyInput.scss';
import { Form, Input } from 'antd';
import { useDebounceEffect } from 'ahooks';
import InputProps from '../InputProps';
import {
  floatToFormattedString, formatCurrencyString, formattedStringToFloat,
} from '../../../lib/Utils';


type InputPropsWithoutValue = Omit<InputProps<number>, 'value'>


interface currencyInputProps extends InputPropsWithoutValue {
  min?: number;
  controls?: boolean;
  inputClassName?: string;
  className?: string;
  required?: boolean;
  readOnly?: boolean;
  value?: number,
  defaultValue?: number,
}

const CURRENCY = '€';

export function CurrencyInput(props: currencyInputProps): JSX.Element {
  const {
    defaultValue,
    inputClassName,
    value,
    label,
    className,
    required,
    disabled,
    validationState,
    validationMessage,
    onChange,
    min,
    ...others
  } = props;

  const [stringValue, setStringValue] = useState(floatToFormattedString(value ?? defaultValue));
  const isInitialRender = useRef(true);


  useDebounceEffect(() => {
    /**
     * the useRef is needed because if we return when `value === undefined`
     * then in case we change the value to `undefined` from the outside of the component
     * it won't rerender and show the correct amount
     */
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }

    if (value !== formattedStringToFloat(stringValue)) {
      setStringValue(floatToFormattedString(value));
    }
    isInitialRender.current = false;
  }, [value], { wait: 200 });


  const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const v = e.target.value;
    if (!(typeof v === 'undefined' || !v || Number.isNaN(Number.parseFloat(v)))) {
      setStringValue(formatCurrencyString(v));
      onChange(formattedStringToFloat(formatCurrencyString(v)));
    } else if (v !== '') {
      onChange(0);
    } else {
      setStringValue(undefined);
    }
  };


  const onBlur = () => {
    if (!stringValue) {
      setStringValue('0,00');
      onChange(0);
      return;
    }

    const floatValue = formattedStringToFloat(stringValue);
    if (min !== undefined && floatValue < min) {
      setStringValue(floatToFormattedString(min));
      onChange(min);
      return;
    }

    // format the value
    setStringValue(floatToFormattedString(formattedStringToFloat(stringValue)));
  };

  return (
    <div className={`CurrencyInput ${className}`}>
      <Form.Item
        label={label || required ? `${label}${required ? ' *' : ''}` : undefined}
        validateStatus={disabled ? '' : (validationState || 'success')}
        help={disabled ? '' : (
          <span className="validation-message">
            {validationMessage}
          </span>
        )}
      >
        <Input
          {...others}
          className={`Input ${inputClassName} ${disabled ? 'disabled' : ''}`}
          inputMode="decimal"
          disabled={disabled}
          defaultValue={floatToFormattedString(defaultValue)}
          value={stringValue}
          readOnly={disabled}
          onChange={onChangeInput}
          suffix={CURRENCY}
          min={min}
          onFocus={e => e.target.select()}
          onClick={e => e.currentTarget.select()}
          onBlur={() => onBlur()}
        />
      </Form.Item>
    </div>
  );
}

CurrencyInput.defaultProps = {
  inputClassName: '',
  className: '',
  required: false,
  readOnly: false,
  min: undefined,
  controls: false,
  value: undefined,
  defaultValue: 0,
};
