import React, { createContext, useState } from 'react';

interface Props {
  children: React.ReactNode;
}

interface SectionManagerValue {
  openSectionId: number | undefined;
  setOpenSectionId: React.Dispatch<React.SetStateAction<number>>,
}

export const SectionManagerContext = createContext<SectionManagerValue | undefined>(undefined);

export const SectionManagerProvider = ({ children }: Props) => {
  const [openSectionId, setOpenSectionId] = useState<number | undefined>(undefined);

  const providerValue = {
    openSectionId,
    setOpenSectionId,
  } as SectionManagerValue;

  return (
    <SectionManagerContext.Provider value={providerValue}>
      {children}
    </SectionManagerContext.Provider>
  );
};
