import { LanguageContext } from 'contexts/LanguageContext';
import { useContext, useEffect, useRef } from 'react';
import { Form } from 'antd';
import './WatermarkEditor.scss';
import Icon from '@ant-design/icons';
import { ICONS } from 'components/icons';
import Button from 'elements/Buttons/Button/Button';
import { Prompt, useParams } from 'react-router';
import TextInput from 'elements/Inputs/TextInput/TextInput';
import SimplePdfViewer from 'elements/PdfViewer/SimplePdfViewer';
import EmptyState from 'storybook-components/feedback/EmptyState/EmptyState';
import { translations } from 'elements/Translation/translations';
import { GlobalSearchContext } from 'components/Header/components/SearchBar/services/GlobalSearchContext';
import Page from 'storybook-components/layout/Page/Page';
import PageContent from 'storybook-components/layout/PageContent/PageContent';
import { watermarkTranslations } from './translations';
import WatermarkEditorHeader from './components/WatermarkEditorHeader/WatermarkEditorHeader';
import useWatermarkEditor from './services/useWatermarkEditor';
import UploadWatermarkInfo from './components/UploadWatermarkInfo/UploadWatermarkInfo';

export default function WatermarkEditor(): JSX.Element {
  const { tl } = useContext(LanguageContext);
  const { watermarkId } = useParams<{ watermarkId: any }>();
  const fileInput = useRef(null);
  const {
    watermark,
    isWatermarkDirty,
    newFileUploaded,
    handleWatermarkSelected,
    onUpdateWatermarkName,
    onSaveWatermark,
    onDeleteWatermark,
  } = useWatermarkEditor(watermarkId);

  const getUploadFileIcon = () => {
    if (watermark?.id) {
      return ICONS.refresh;
    }
    return ICONS.upload;
  };

  const getUploadFileText = () => {
    if (watermark?.id) {
      return tl(watermarkTranslations.replaceFile);
    }

    return tl(watermarkTranslations.selectFile);
  };

  const handleUploadFile = () => {
    // Force clear the input value in case user selects the same file.
    fileInput.current.value = null;
    fileInput.current.click();
  };

  const handleDeleteWatermark = () => {
    onDeleteWatermark(watermark?.id);
  };

  const hasPdf = watermark?.url || watermark?.base64Url;

  const {
    setHideGlobalSearch,
  } = useContext(GlobalSearchContext);

  useEffect(() => {
    // hide search on mount
    setHideGlobalSearch(true);

    return () => {
      // unhide search bar on unmount
      setHideGlobalSearch(false);
    };
  }, []);

  return (
    <Page className="UploadWatermarkModal">
      <Prompt when={isWatermarkDirty} message={tl(translations.elements.prompt)} />
      <WatermarkEditorHeader saveButtonDisabled={!watermark} onSave={onSaveWatermark} watermarkId={watermark?.id} isWatermarkDirty={isWatermarkDirty} newFileUploaded={newFileUploaded} />
      <div className="form-container">
        <div className="pdf-viewer">
          {hasPdf && <SimplePdfViewer filename={watermark.name} base64Url={watermark?.base64Url} url={watermark?.url} />}
          {!hasPdf
            && (
              <EmptyState
                icon={<Icon component={ICONS.file} />}
                title={tl(watermarkTranslations.noFileYet)}
                subTitle={tl(watermarkTranslations.youCanUploadPdf)}
              />
            )}
        </div>
        <PageContent className="upload-info">
          <Form
            name="watermark-upload"
          >
            <Form.Item label={tl(watermarkTranslations.name)}>
              <TextInput
                value={watermark?.name}
                placeholder={tl(watermarkTranslations.filename)}
                onChange={onUpdateWatermarkName}
              />
            </Form.Item>
          </Form>

          <div className="bottom-container">
            <UploadWatermarkInfo />
            <div className="actions-container">
              <input
                type="file"
                ref={fileInput}
                style={{ display: 'none' }}
                onChange={handleWatermarkSelected}
                accept=".pdf"
              />
              <Button className="action-button" onClick={handleUploadFile}>
                <Icon component={getUploadFileIcon()} />
                {getUploadFileText()}
              </Button>

              <Button className="action-button" disabled={!watermark?.id} onClick={handleDeleteWatermark}>
                <Icon component={ICONS.bin} />
                {tl(watermarkTranslations.delete)}
              </Button>
            </div>
          </div>
        </PageContent>
      </div>
    </Page>
  );
}
