import React from 'react';
import { Form } from 'antd';
import { digitNumberFormatter, numberInputParser } from 'lib/Utils';
import { InfoIcon } from 'storybook-components/InfoIcon/InfoIcon';
import InputProps from '../InputProps';
import InputNumber from './InputNumber';
import './InputNumber.scss';

interface numberInputProps extends InputProps<number> {
  // eslint-disable-next-line react/require-default-props
  min?: number
  // eslint-disable-next-line react/require-default-props
  max?: number
  // eslint-disable-next-line react/require-default-props
  precision?: 1|2|4|5,
  required?: boolean,
  controls?: boolean,
}

const DECIMAL_SEPARATOR = ',';
export default function NumberInput(props: numberInputProps): JSX.Element {
  const {
    className,
    inputClassName,
    label,
    min,
    max,
    precision,
    value,
    disabled,
    required,
    validationState,
    validationMessage,
    showPlaceholderWhenDisabled,
    autoFocus,
    infoText,
    controls,
  } = props;
  const onChange = (v: number | undefined) => {
    // @ts-ignore
    if (v === '') return;
    if (typeof v === 'undefined') props.onChange(0);
    else props.onChange(v);
  };
  let { placeholder } = props;
  if (disabled && !showPlaceholderWhenDisabled) {
    placeholder = '';
  }
  const parser = (val: any) => {
    if (precision) return numberInputParser(val, precision);
    return parseFloat(val.replace(/\D/g, ''));
  };

  const getLabel = () => {
    if (!label && !infoText) {
      // otherwise we'll always return a fragment, so we'll always have a label
      return null;
    }

    return (
      <>
        {
          (typeof label === 'string' && label) ? (
            <span>
              {label}
              <span>{`${required ? ' *' : ''}`}</span>
            </span>
          ) : label
        }
        {infoText && <InfoIcon popupText={infoText} size="small" />}
      </>
    );
  };

  return (
    <div className={`NumberInput ${className}`}>
      <Form.Item
        label={getLabel()}
        validateStatus={disabled ? '' : (validationState || 'success')}
        help={disabled ? '' : (
          <span className="validation-message">
            {validationMessage}
          </span>
        )}
      >
        <InputNumber
          className={`Input InputNumber ${inputClassName}`}
          precision={precision || 0}
          min={min}
          decimalSeparator={DECIMAL_SEPARATOR}
          max={max}
          value={value}
          onChange={onChange}
          parser={parser}
          formatter={val => digitNumberFormatter(val, precision)}
          placeholder={placeholder}
          disabled={disabled || false}
          onFocus={e => e.target.select()}
          autoFocus={autoFocus}
          controls={controls}
        />
      </Form.Item>
    </div>
  );
}

NumberInput.defaultProps = {
  required: false,
};
