import React from 'react';
import moment from 'moment';
import EconomicYearPicker from '../../../../../../../elements/Inputs/EconomicYearPicker/EconomicYearPicker';
import { useRightSideComponents } from './useRightSideComponents';

export default function RightSideComponents(): JSX.Element {
  const {
    generatedOnLabel, selectedEconomicYear, selectedProperty, setSelectedEconomicYear,
  } = useRightSideComponents();

  const economicYearStart: Date = selectedProperty.data ? selectedProperty.data.economicYearStart : null;
  const currentYear = moment().get('year');

  return (
    <>
      <span className="creation-date">
        {generatedOnLabel}
      </span>
      <EconomicYearPicker
        className="EconomicYearPickerWithPropertyInfo"
        type="label"
        value={selectedEconomicYear}
        onChange={setSelectedEconomicYear as (value: number) => void}
        economicYearStart={economicYearStart}
        fromYear={currentYear - 5}
        toYear={currentYear + 3}
      />
    </>
  );
}
