import React, {
  Context, useContext, useEffect, useMemo, useRef, useState,
} from 'react';
import moment from 'moment';
import {
  DocumentApi, DocumentDto, DocumentDtoStateEnum, GetDocumentsByFilterUsingGETOrderEnum, GetDocumentsByFilterUsingGETSourceTypeEnum,
} from 'api/public';
import { MAX_PAGE_SIZE } from 'lib/messageUtils';
import _, { isEmpty } from 'lodash';
import DEFAULT_DATA, { DefaultDataInterface } from '../lib/data';
import { PropertyListContext } from './PropertyListContext';
import backend, { endpointUrls } from '../backend_api';
import { LanguageContext } from './LanguageContext';
import { translations } from '../elements/Translation/translations';
import { ReportContextDefaultReturn, ReportContextReturnType } from '../pages/ReportOverview/ReportContextReturnType';
import { showNotification } from '../lib/Notification';
import {
  CDAccountBalanceDto, HouseMoneySettlementDto, HouseMoneySettlementDtoStatusEnum, UnitAccountBalanceDto, UnitHouseMoneySettlementDto,
} from '../api/accounting/models';
import { HouseMoneySettlementAggregationControllerApi, PropertyLegacyDtoPropertyStateEnum, FindFilteredPropertiesUsingGETAdministrationTypesEnum } from '../api/accounting';
import { AuthContext } from './AuthContext';

export const HouseMoneySettlementContext: Context<ReportContextReturnType> = React.createContext(ReportContextDefaultReturn);

export default function HouseMoneySettlementProvider({ children }: any) {
  const { tl } = useContext(LanguageContext);

  const [houseMoneySettlementListState, setHouseMoneySettlementListState] = useState(DEFAULT_DATA<any>([]));
  const [houseMoneySettlementState, setHouseMoneySettlementState] = useState(DEFAULT_DATA<any>({}));
  const { selectedProperty } = useContext(PropertyListContext);
  const selectedPropertyId = selectedProperty.data ? selectedProperty.data.id : null;
  const [selectedEconomicYear, setSelectedEconomicYear] = useState(moment().get('year') - 1);
  const [houseMoneySettlementForSingleUnitState, setHouseMoneySettlementForSingleUnitState] = useState(DEFAULT_DATA<any>({}));
  const [selectedUnitId, setSelectedUnitId] = useState(null);

  const [filterState, setFilterState] = useState<any>({});
  const [initialFilterUpdate, setInitialFilterUpdate] = useState(true);

  const lastRequestTimestamp = useRef<number | null>(null);

  const [inCreation, setInCreation] = useState(false);
  const [creationFailed, setCreationFailed] = useState(false);
  const [documentsInCreation, setDocumentsInCreation] = useState(false);
  const [documents, setDocuments] = useState<DefaultDataInterface<DocumentDto[]>>(DEFAULT_DATA([]));
  const abortController = useRef<AbortController | undefined>(undefined);
  const documentAbortController = useRef<AbortController | undefined>(undefined);

  const { apiConfiguration, publicApiConfiguration } = useContext(AuthContext);

  const houseMoneySettlementController = new HouseMoneySettlementAggregationControllerApi(apiConfiguration('accounting'));
  const documentControllerApi = new DocumentApi(publicApiConfiguration('public'));

  useEffect(() => {
    if (!initialFilterUpdate) {
      onLoadHouseMoneySettlementList(true);
    } else {
      setInitialFilterUpdate(false);
    }
  }, [filterState]);

  useEffect(() => {
    onLoadHouseMoneySettlementForUnit();
  }, [selectedUnitId, selectedEconomicYear]);

  const onLoadHouseMoneySettlementList = (resetPage: boolean = false) => {
    const currentTimestamp = new Date().getTime();
    lastRequestTimestamp.current = currentTimestamp;

    setHouseMoneySettlementListState(houseMoneySettlementListState.startLoading());
    const currentYear = new Date().getFullYear();
    let neededEconomicYear = currentYear - 1;
    backend.get(`${endpointUrls.PROPERTY}/filter`, {
      ...filterState,
      excludeFields: ['bankAccounts'],
      propertyStates: [PropertyLegacyDtoPropertyStateEnum.READY, PropertyLegacyDtoPropertyStateEnum.OFFBOARDED],
      administrationTypes: [FindFilteredPropertiesUsingGETAdministrationTypesEnum.WEG],
      page: resetPage ? 0 : houseMoneySettlementListState.page,
      sort: 'propertyHrId',
      order: 'ASC',
      size: 30,
    }).then(async (response: any) => {
      try {
        const promises: any[] = [];
        const propertiesWithHouseMoneySettlement: any[] = response.content;
        if (response.content.economicYearStart) {
          const currentDate = new Date();
          const economicYearStart = new Date(response.content.economicYearStart);
          economicYearStart.setFullYear(currentYear);
          if (currentDate < economicYearStart) {
            neededEconomicYear += 1;
          }
        }
        response.content.forEach((property: any) => {
          promises.push(houseMoneySettlementController.getHouseMoneySettlementStatusUsingGET({ propertyId: property.id, economicYear: neededEconomicYear })
            .then((houseMoneySettlement) => {
              const propertyIndex = propertiesWithHouseMoneySettlement.findIndex(propertyWithHouseMoneySettlement => property.id === propertyWithHouseMoneySettlement.id);
              propertiesWithHouseMoneySettlement[propertyIndex].houseMoneySettlement = houseMoneySettlement;
            })
            .catch((error) => {
              if (error.response.status === 404) {
                const propertyIndex = propertiesWithHouseMoneySettlement.findIndex(propertyWithHouseMoneySettlement => property.id === propertyWithHouseMoneySettlement.id);
                propertiesWithHouseMoneySettlement[propertyIndex].houseMoneySettlement = {
                  status: 'TO_DO',
                };
              } else {
                throw error;
              }
            }));
        });
        await Promise.all(promises);

        // do nothing if this is a response for an older request
        if (currentTimestamp !== lastRequestTimestamp.current) return;

        setHouseMoneySettlementListState((state: any) => state.loadPaged(propertiesWithHouseMoneySettlement, resetPage, response.last));
      } catch (e) {
        setHouseMoneySettlementListState(houseMoneySettlementListState.failed());
        showNotification({
          key: 'loadHouseMoneySettlement',
          message: tl(translations.notifications.houseMoneySettlementContext.loadHouseMoneySettlementError.message),
          type: 'error',
        });
      }
    })
      .catch(() => {
        setHouseMoneySettlementListState(houseMoneySettlementListState.failed());
        showNotification({
          key: 'loadProperties',
          message: tl(translations.notifications.propertyListContext.loadError.message),
          description: tl(translations.notifications.propertyListContext.loadError.description),
          type: 'error',
        });
      });
  };

  const setStatusFlags = (houseMoneySettlement: HouseMoneySettlementDto, docs: DocumentDto[]) => {
    setInCreation(houseMoneySettlement.status === HouseMoneySettlementDtoStatusEnum.IN_CREATION);
    setCreationFailed(houseMoneySettlement.status === HouseMoneySettlementDtoStatusEnum.FAILED || !_.isEmpty(docs?.filter(d => d.state === DocumentDtoStateEnum.FAILED)));
    setDocumentsInCreation((![HouseMoneySettlementDtoStatusEnum.IN_CREATION, HouseMoneySettlementDtoStatusEnum.FAILED].includes(houseMoneySettlement.status) && isEmpty(docs))
     || docs?.some(d => [DocumentDtoStateEnum.GENERATING, DocumentDtoStateEnum.DRAFT].includes(d.state)));
  };

  const pollStatus = () => {
    houseMoneySettlementController.getHouseMoneySettlementStatusUsingGET({ propertyId: selectedPropertyId, economicYear: selectedEconomicYear })
      .then((houseMoneySettlement: any) => {
        setStatusFlags(houseMoneySettlement, []);
        if (houseMoneySettlement.status === HouseMoneySettlementDtoStatusEnum.DRAFT || houseMoneySettlement.status === HouseMoneySettlementDtoStatusEnum.FAILED) {
          onLoadHouseMoneySettlement();
        }
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const pollDocumentStatus = () => {
    documentAbortController.current?.abort();
    documentAbortController.current = new AbortController();
    const { signal } = abortController.current;

    documentControllerApi.getDocumentsByFilterUsingGET({
      sourceId: houseMoneySettlementState.data?.id,
      sourceType: GetDocumentsByFilterUsingGETSourceTypeEnum.HOUSE_MONEY_SETTLEMENT,
      size: MAX_PAGE_SIZE,
      sort: 'unitRank',
      order: GetDocumentsByFilterUsingGETOrderEnum.ASC,
    }, { signal })
      .then((resp) => {
        setDocuments(prev => prev.load(resp.content));
        setDocumentsInCreation(resp.content?.some(d => [DocumentDtoStateEnum.GENERATING, DocumentDtoStateEnum.DRAFT].includes(d.state)));
        setCreationFailed(resp.content?.some(d => [DocumentDtoStateEnum.FAILED].includes(d.state)));
      })
      .catch((e) => {
        if (signal?.aborted) return;
        console.error(e);
      });
  };
  const onLoadHouseMoneySettlement = () => {
    if (selectedPropertyId && selectedEconomicYear) {
      abortController.current?.abort();
      abortController.current = new AbortController();
      const { signal } = abortController.current;

      setHouseMoneySettlementState(currentState => currentState.startLoading());

      houseMoneySettlementController.getHouseMoneySettlementV2UsingGET({ propertyId: selectedPropertyId, economicYear: selectedEconomicYear }, { signal })
        .then((houseMoneySettlement: any) => {
          setHouseMoneySettlementState(currentState => currentState.load(convertHouseMoneySettlementToFEModel(houseMoneySettlement)));
          documentControllerApi.getDocumentsByFilterUsingGET({
            sourceId: houseMoneySettlement.id,
            sourceType: GetDocumentsByFilterUsingGETSourceTypeEnum.HOUSE_MONEY_SETTLEMENT,
            size: MAX_PAGE_SIZE,
            sort: 'unitRank',
            order: GetDocumentsByFilterUsingGETOrderEnum.ASC,
          }).then((resp) => {
            setDocuments(prev => prev.load(resp.content));
            setStatusFlags(houseMoneySettlement, resp.content);
          });
        })
        .catch(() => {
          if (signal?.aborted) return;
          setHouseMoneySettlementState(currentState => currentState.failed());
          showNotification({
            key: 'loadHouseMoneySettlementError',
            message: tl(translations.notifications.houseMoneySettlementContext.loadHouseMoneySettlementError.message),
            type: 'error',
          });
        });
    }
  };

  const convertHouseMoneySettlementToFEModel = (houseMoneySettlementDto: HouseMoneySettlementDto) => {
    const houseMoneySettlement: any = houseMoneySettlementDto;
    houseMoneySettlement.results.applicableExpenses = convertAccountBalancesToFEModel(houseMoneySettlement.results.applicableExpenses);
    houseMoneySettlement.results.notApplicableExpenses = convertAccountBalancesToFEModel(houseMoneySettlement.results.notApplicableExpenses);
    houseMoneySettlement.results.income = convertAccountBalancesToFEModel(houseMoneySettlement.results.income);

    return houseMoneySettlement;
  };

  const convertAccountBalancesToFEModel = (accountBalances: CDAccountBalanceDto[]): any[] => accountBalances.map((accountBalance: any) => {
    let tempChildren = null;
    if (accountBalance.subAccounts) {
      tempChildren = convertAccountBalancesToFEModel(accountBalance.subAccounts);
    }

    return {
      ...accountBalance,
      account: `${accountBalance.accountCode} ${accountBalance.accountName}`,
      children: tempChildren,
      newTotalShares: tempChildren ? tempChildren.reduce((total, ab) => total + ab.totalSum, 0) : accountBalance.totalShares,
      newTotalAmount: tempChildren ? tempChildren.reduce((total, ab) => total + ab.totalSum, 0) : accountBalance.totalSum,
      totalSum: tempChildren ? tempChildren.reduce((total, ab) => total + ab.totalSum, 0) : accountBalance.totalSum,
      totalShares: tempChildren ? tempChildren.reduce((total, ab) => total + ab.totalSum, 0) : accountBalance.totalShares,
    };
  }).filter((accountBalance: any) => accountBalance.totalSum !== 0);

  const prepaymentCompare = (a: any, b: any) => {
    const aMoment = moment(a.date);
    const bMoment = moment(b.date);
    if (aMoment.isSame(bMoment, 'day')) {
      return a.amount < b.amount;
    }
    return aMoment.isAfter(bMoment);
  };

  const sortPrepayments = (houseMoneySettlementForUnit: any) => {
    if (houseMoneySettlementForUnit.houseMoneyPrepayments) {
      houseMoneySettlementForUnit.houseMoneyPrepayments.forEach((houseMoneyPrepayment: any) => {
        if (houseMoneyPrepayment.prepayments) {
          houseMoneyPrepayment.prepayments.sort(prepaymentCompare);
        }
      });
    }

    if (houseMoneySettlementForUnit.reserveFundsPrepayments) {
      houseMoneySettlementForUnit.reserveFundsPrepayments.forEach((reserveFundsPrepayment: any) => {
        if (reserveFundsPrepayment.prepayments) {
          reserveFundsPrepayment.prepayments.sort(prepaymentCompare);
        }
      });
    }

    return houseMoneySettlementForUnit;
  };

  const onLoadHouseMoneySettlementForUnit = () => {
    if (selectedPropertyId && selectedUnitId && selectedEconomicYear) {
      setHouseMoneySettlementForSingleUnitState(currentState => currentState.startLoading());

      houseMoneySettlementController.getHouseMoneySettlementForUnitUsingGET({ propertyId: selectedPropertyId, unitId: selectedUnitId!, economicYear: selectedEconomicYear })
        .then((houseMoneySettlementForUnit: any) => {
          setHouseMoneySettlementForSingleUnitState(currentState => currentState.load(convertUnitHouseMoneySettlementToFEModel(houseMoneySettlementForUnit)));
        })
        .catch((err) => {
          setHouseMoneySettlementForSingleUnitState(currentState => currentState.failed());

          if (err.response.status === 404) {
            console.warn(err);
            showNotification({
              key: 'houseMoneySettlementNotFound',
              message: tl(translations.notifications.houseMoneySettlementContext.houseMoneySettlementNotFound),
              type: 'error',
            });
          } else {
            console.error(err);
            showNotification({
              key: 'loadHouseMoneySettlementError',
              message: tl(translations.notifications.houseMoneySettlementContext.loadHouseMoneySettlementError.message),
              type: 'error',
            });
          }
        });
    }
  };

  const convertUnitHouseMoneySettlementToFEModel = (unitHouseMoneySettlementDto: UnitHouseMoneySettlementDto) => {
    const unitHouseMoneySettlement = sortPrepayments(unitHouseMoneySettlementDto);

    unitHouseMoneySettlement.applicableExpenses = convertUnitAccountBalancesToFEModel(unitHouseMoneySettlement.applicableExpenses || []);
    unitHouseMoneySettlement.notApplicableExpenses = convertUnitAccountBalancesToFEModel(unitHouseMoneySettlement.notApplicableExpenses || []);
    unitHouseMoneySettlement.incomes = convertUnitAccountBalancesToFEModel(unitHouseMoneySettlement.incomes || []);

    return unitHouseMoneySettlement;
  };

  const convertUnitAccountBalancesToFEModel = (accountBalances: UnitAccountBalanceDto[]): any[] => accountBalances.map((accountBalance: any) => {
    let tempChildren = null;
    if (accountBalance.subAccounts) {
      tempChildren = convertUnitAccountBalancesToFEModel(accountBalance.subAccounts);
    }

    return {
      ...accountBalance,
      account: `${accountBalance.accountCode} ${accountBalance.accountName}`,
      tempChildren,
      newTotalShares: tempChildren ? tempChildren.reduce((total, ab) => total + ab.totalSum, 0) : accountBalance.totalShares,
      newUnitShares: tempChildren ? tempChildren.reduce((total, ab) => total + ab.totalSum, 0) : accountBalance.unitShares,
      newTotalAmount: tempChildren ? tempChildren.reduce((total, ab) => total + ab.totalSum, 0) : accountBalance.totalSum,
      newUnitAmount: tempChildren ? tempChildren.reduce((total, ab) => total + ab.unitSum, 0) : accountBalance.unitSum,
      totalSum: tempChildren ? tempChildren.reduce((total, ab) => total + ab.totalSum, 0) : accountBalance.totalSum,
      unitSum: tempChildren ? tempChildren.reduce((total, ab) => total + ab.unitSum, 0) : accountBalance.unitSum,
    };
  }).filter((accountBalance: any) => accountBalance.unitSum !== 0);


  const onClearHouseMoneySettlementState = () => {
    setHouseMoneySettlementState(DEFAULT_DATA<any>({}));
    setInCreation(false);
    setCreationFailed(false);
    setDocumentsInCreation(false);
    setDocuments(DEFAULT_DATA([]));
  };

  const onClearFilterState = () => {
    setFilterState({});
  };

  const updateFilterState = (data: object) => {
    setFilterState({
      ...filterState,
      ...data,
    });
  };

  return (
    <HouseMoneySettlementContext.Provider value={{
      houseMoneySettlementListState,
      selectedEconomicYear,
      setSelectedEconomicYear,
      selectedUnitId,
      setSelectedUnitId,
      houseMoneySettlementState,
      houseMoneySettlementForSingleUnitState,
      setFilterState,
      filterState,
      selectedProperty,
      selectedPropertyId,
      inCreation,
      documentsInCreation,
      creationFailed,
      documents: documents.data,
      onLoadHouseMoneySettlementList,
      onLoadHouseMoneySettlement,
      setStatusFlags,
      updateFilterState,
      onClearHouseMoneySettlementState,
      onClearFilterState,
      pollStatus,
      pollDocumentStatus,
      setHouseMoneySettlementState,
    }}
    >
      {children}
    </HouseMoneySettlementContext.Provider>
  );
}
