import { LanguageContext } from 'contexts/LanguageContext';
import { useContext } from 'react';
import { useSectionManager } from 'services/SectionManager/useSectionManager';
import { DefaultSectionSummaryContent } from 'storybook-components/sections/SectionSummary/services/interfaces';
import { formatDate, generateReportDocumentName } from 'lib/Utils';
import { isEmpty } from 'lodash';
import { useHistory } from 'react-router';
import { DocumentCreateDtoSourceTypeEnum } from 'api/document';
import {
  PNLValidationError, PNLValidationErrors, ProfitAndLossReportContext, ReportCreationData,
} from '../../../services/ProfitAndLossReportContext';
import { translations } from '../../../../translations';
import { useGetProfitAndLossReportData } from './useGetProfitAndLossReportData';


export const useProfitAndLossReportForm = () => {
  const { tl } = useContext(LanguageContext);
  const history = useHistory();
  const profitAndLossReportContext = useContext(ProfitAndLossReportContext);
  const {
    reportData, setReportData, validationErrors, setValidationErrors, accountBalances,
  } = profitAndLossReportContext;
  const { openSectionId, onOpenSpecificSection } = useSectionManager(0);
  const { getProfitAndLossReportData } = useGetProfitAndLossReportData();

  const onChange = (key: keyof ReportCreationData, value: ReportCreationData[keyof ReportCreationData]) => {
    setReportData((prev) => {
      if (key !== 'documentName' && !prev.isDocumentNameTouched) {
        const newState = { ...prev, [key]: value };

        setValidationErrors(prevValidation => ({
          ...prevValidation,
          [key]: undefined,
          documentName: undefined,
        }));

        return { ...newState, documentName: generateReportDocumentName(DocumentCreateDtoSourceTypeEnum.PROFIT_AND_LOSS, newState?.startDate, newState?.endDate) };
      }

      setValidationErrors(prevValidation => ({
        ...prevValidation,
        [key]: undefined,
      }));
      return { ...prev, [key]: value, isDocumentNameTouched: true };
    });
  };

  // Opening other sections is only allowed by clicking the Calculate button
  const onClickEditorFormSection = () => {
    if (openSectionId !== 0) {
      onOpenSpecificSection(0);
    }
  };


  const sectionSummary: (DefaultSectionSummaryContent | React.ReactNode)[] = [
    {
      title: tl(translations.report.form.sectionSummary.select),
      value: reportData?.property?.propertyIdInternal,
    },
    {
      title: tl(translations.report.form.sectionSummary.starteDate),
      value: formatDate(reportData?.startDate),
    },
    {
      title: tl(translations.report.form.sectionSummary.endDate),
      value: formatDate(reportData?.endDate),
    },
    {
      title: tl(translations.report.form.sectionSummary.docTitle),
      value: reportData?.documentName,
    },
  ];


  const getValidationErrors = (reportDataArg: ReportCreationData) => {
    const errors: PNLValidationErrors = {};

    if (reportDataArg.property === undefined) {
      errors.property = {
        state: 'error',
        message: tl(translations.report.form.validation.required),
      };
    }

    if (!reportDataArg.documentName) {
      errors.documentName = {
        state: 'error',
        message: tl(translations.report.form.validation.required),
      };
    }

    if (!reportDataArg.endDate) {
      errors.endDate = {
        state: 'error',
        message: tl(translations.report.form.validation.required),
      };
    }

    if (!reportDataArg.startDate) {
      errors.startDate = {
        state: 'error',
        message: tl(translations.report.form.validation.required),
      };
    }

    if (reportDataArg.endDate && reportDataArg.startDate && reportDataArg.endDate.isBefore(reportDataArg.startDate)) {
      const invalidDateError: PNLValidationError = {
        state: 'error',
        message: tl(translations.report.form.validation.invalidDateRange),
      };

      errors.startDate = invalidDateError;
      errors.endDate = invalidDateError;
    }

    return errors;
  };

  const onCalculate = () => {
    onCalculateFromArguments(reportData);
  };

  const onCalculateFromArguments = (reportDataArg: ReportCreationData, triggeredByInstantCalculate?: Boolean) => {
    const errors: PNLValidationErrors = getValidationErrors(reportDataArg);

    setValidationErrors(errors);
    if (isEmpty(errors)) {
      getProfitAndLossReportData({ reportData: reportDataArg, onSuccessCb: () => onOpenSpecificSection(1) });
      const propertyId = reportDataArg.property?.id;
      const documentName = encodeURIComponent(reportDataArg.documentName);
      const urlStartDate = encodeURIComponent(formatDate(reportDataArg.startDate, 'YYYY-MM-DD'));
      const urlEndDate = encodeURIComponent(formatDate(reportDataArg.endDate, 'YYYY-MM-DD'));
      let urlWithParams = `${history.location.pathname}?propertyId=${propertyId}&name=${documentName}&startDate=${urlStartDate}&endDate=${urlEndDate}`;
      if (triggeredByInstantCalculate) {
        urlWithParams += '&instantCalculate=true';
      }
      history.replace(urlWithParams);
    }
  };


  return {
    onChange,
    reportData,
    onClickEditorFormSection,
    openSectionId,
    sectionSummary,
    onCalculate,
    onCalculateFromArguments,
    validationErrors,
    loading: accountBalances.loading,
  };
};
