import { useContext } from 'react';
import { DocumentCreateDtoOcrStateEnum, DocumentCreateDtoSourceTypeEnum } from 'api/document';
import { LanguageContext } from '../../../../../contexts/LanguageContext';
import { OwnersMeetingProtocolDtoStatusEnum } from '../../../../../api/accounting';
import { ownersMeetingInvitationTranslations } from '../../translations/OwnersMeetingInvitationTranslations';

export const UseStandardDocuments = (ownersMeeting: any) => {
  const { tl } = useContext(LanguageContext);
  const isSent = [OwnersMeetingProtocolDtoStatusEnum.SENT, OwnersMeetingProtocolDtoStatusEnum.DONE].includes(ownersMeeting.status);

  function customPdfs() {
    const documents = ownersMeeting.documents ? ownersMeeting.documents : [];
    const uploadedFiles: any[] = [];
    documents.forEach((document: any, i: number) => {
      uploadedFiles.push([{
        type: 'fileLine',
        key: `documents[${i}]`,
        value: document,
        props: {
          descriptionLabel: document.name,
          nameKey: 'name',
          urlKey: 'url',
          name: 'newDocument',
          disabled: isSent,
          sourceType: DocumentCreateDtoSourceTypeEnum.SERIAL_LETTER,
        },
      }]);
    });
    const customFiles = uploadedFiles;
    customFiles.push([
      {
        type: 'fileLine',
        key: `documents[${documents.length}]`,
        props: {
          descriptionLabel: tl(ownersMeetingInvitationTranslations.editPage.sections.documentsSection.subsections.standardDocuments.content.addNew.description),
          buttonLabel: tl(ownersMeetingInvitationTranslations.editPage.sections.documentsSection.subsections.standardDocuments.content.addNew.buttonLabel),
          nameKey: 'name',
          urlKey: 'url',
          name: `documents[${documents.length}]`,
          accept: '.pdf',
          disabled: isSent,
          sourceType: DocumentCreateDtoSourceTypeEnum.SERIAL_LETTER,
        },
      },
    ]);
    return customFiles;
  }

  return {
    sectionId: 'standardDocuments',
    title: tl(ownersMeetingInvitationTranslations.editPage.sections.documentsSection.subsections.standardDocuments.title),
    content: [
      [
        {
          type: 'fileLine',
          key: 'coverLetter',
          props: {
            descriptionLabel: tl(ownersMeetingInvitationTranslations.editPage.sections.documentsSection.subsections.standardDocuments.content.coverLetter.description),
            disabledButtonLabel: tl(ownersMeetingInvitationTranslations.editPage.sections.documentsSection.subsections.standardDocuments.content.coverLetter.buttonLabel),
            disabled: true,
            sourceType: DocumentCreateDtoSourceTypeEnum.SERIAL_LETTER,
          },
        },
      ],
      [
        {
          type: 'fileLine',
          key: 'Agenda',
          props: {
            descriptionLabel: tl(ownersMeetingInvitationTranslations.editPage.sections.documentsSection.subsections.standardDocuments.content.agenda.description),
            disabledButtonLabel: tl(ownersMeetingInvitationTranslations.editPage.sections.documentsSection.subsections.standardDocuments.content.agenda.buttonLabel),
            disabled: true,
            sourceType: DocumentCreateDtoSourceTypeEnum.OTHER,
            ocrState: DocumentCreateDtoOcrStateEnum.NO_OCR,
          },
        },
      ],
      ...customPdfs(),
    ],
  };
};
