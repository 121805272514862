import {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { Configuration, DocumentLegacyControllerApi, FindDocumentsFilteredUsingGETSourceTypesEnum } from 'api/document';
import { AuthContext } from 'contexts/AuthContext';
import { LanguageContext } from '../contexts/LanguageContext';
import DEFAULT_DATA from '../lib/data';
import { translations } from '../elements/Translation/translations';
import { Watermark } from '../data/watermark';
import { SelectOption } from '../elements/Inputs/SelectInput/SelectInput';
import { showNotification } from '../lib/Notification';

const optionObjectFromWatermark = (watermark: Watermark): SelectOption => ({
  value: watermark.id,
  label: watermark.name,
});

export default function useWatermarkOptions() {
  const { tl } = useContext(LanguageContext);
  const [watermarksState, setWatermarksState] = useState(DEFAULT_DATA<Watermark[]>([]));

  const { documentApiConfiguration } = useContext(AuthContext);
  const documentControllerApi = new DocumentLegacyControllerApi(documentApiConfiguration('document') as unknown as Configuration);

  const watermarkOptions: SelectOption[] = useMemo(() => (watermarksState.data ? watermarksState.data.map(optionObjectFromWatermark) : []), [watermarksState.data]);
  const onLoadWatermarks = () => {
    setWatermarksState(state => state.startLoading());
    documentControllerApi.findDocumentsFilteredUsingGET({ sourceTypes: FindDocumentsFilteredUsingGETSourceTypesEnum.WATERMARK })
      .then((response: any) => {
        setWatermarksState(state => state.load(response.content as unknown as Watermark[]));
      })
      .catch((error: Error) => {
        console.error(error);
        setWatermarksState(state => state.failed());
        showNotification({
          key: 'loadWatermarksError',
          message: tl(translations.notifications.watermarkHook.error),
          type: 'error',
        });
      });
  };

  useEffect(() => {
    onLoadWatermarks();
  }, []);

  return {
    watermarkOptions,
  };
}
