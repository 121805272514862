import { getEnvVar } from 'lib/getEnvVar';


export const useAlertOnDashboard = () => {
  const title = getEnvVar('notification.title');
  const identifier = getEnvVar('notification.identifier');
  const description = getEnvVar('notification.description');
  const descriptionList = Array.from(getEnvVar('notification.descriptionList')) as Array<string>;
  const readMoreLink = getEnvVar('notification.readMoreLink');

  return {
    title,
    identifier,
    description,
    descriptionList,
    readMoreLink,
  };
};
