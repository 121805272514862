import { LanguageContext } from 'contexts/LanguageContext';
import _ from 'lodash';
import { useMessageSendingContext } from 'pages/MessageSendingPage/services/MessageSendingContext';
import { messageSendingTranslations } from 'pages/MessageSendingPage/translations';
import { useContext } from 'react';
import { AlertArticleLink } from 'storybook-components/feedback/AlertArticleLink/AlertArticleLink';
import { articleUrls } from 'storybook-components/feedback/AlertArticleLink/ArticleUrls';
import ContactLink from './ContactLink/ContactLink';

export const useWarningList = (sectionNr: number) => {
  const { tl } = useContext(LanguageContext);

  const messageSendingContext = useMessageSendingContext('useWarningList');

  const { warnings } = messageSendingContext;

  const getAlerts = () => {
    const tempAlerts = [];
    if (!_.isEmpty(warnings.documentsWithMissingRecipient) && sectionNr === 1) {
      tempAlerts.push({
        title: tl(messageSendingTranslations.warnings.missingRecipientTitle),
        description: (
          <>
            <div>{tl(messageSendingTranslations.warnings.missingRecipientLine1)}</div>
            <ol>
              {warnings.documentsWithMissingRecipient?.map(d => (
                <li>
                  {d}
                </li>
              ))}
            </ol>
            <div>{tl(messageSendingTranslations.warnings.missingRecipientLine2)}</div>
          </>
        ),
      });
    }

    if (!_.isEmpty(warnings.contactsWithMissingDispatch) && sectionNr === 2) {
      tempAlerts.push({
        sectionNr: 2,
        title: tl(messageSendingTranslations.warnings.missingDispatchTypeTitle),
        description: (
          <>
            <div>{tl(messageSendingTranslations.warnings.missingDispatchTypeLine1)}</div>
            <ol>
              {warnings.contactsWithMissingDispatch?.map(c => (
                <li>
                  <ContactLink contactId={c} />
                </li>
              ))}
            </ol>
            <div>{tl(messageSendingTranslations.warnings.missingDispatchTypeLine2)}</div>
            <AlertArticleLink content={tl(messageSendingTranslations.warnings.missingDispatchTypeArticeTitle)} url={articleUrls.messaging.missingDispatchType} />
          </>
        ),
      });
    }

    if (!_.isEmpty(warnings.contactsWithMissingAddresses) && sectionNr === 2) {
      tempAlerts.push({
        sectionNr: 2,
        title: tl(messageSendingTranslations.warnings.missingAddressTitle),
        description: (
          <>
            <div>{tl(messageSendingTranslations.warnings.missingAddressLine1)}</div>
            <ol>
              {warnings.contactsWithMissingAddresses?.map(c => (
                <li>
                  <ContactLink contactId={c} />
                </li>
              ))}
            </ol>
            <div>{tl(messageSendingTranslations.warnings.missingAddressLine2)}</div>
            <AlertArticleLink content={tl(messageSendingTranslations.warnings.missingAddressArticleTitle)} url={articleUrls.messaging.missingAddress} />
          </>
        ),
      });
    }

    if (!_.isEmpty(warnings.activeConnections) && sectionNr === 2) {
      tempAlerts.push({
        sectionNr: 2,
        title: tl(messageSendingTranslations.warnings.moreActivePortalsTitle),
        description: (
          <>
            <div>{tl(messageSendingTranslations.warnings.moreActivePortalsLine1)}</div>
            <ol>
              {warnings.activeConnections?.map(c => (
                <li>
                  {c}
                </li>
              ))}
            </ol>
            <div>
              {tl(messageSendingTranslations.warnings.moreActivePortalsLine2Start)}
              <a target="_blank" href="mailto:support@impower.de" rel="noreferrer">
                {tl(messageSendingTranslations.warnings.moreActivePortalsSupport)}
              </a>
              {tl(messageSendingTranslations.warnings.moreActivePortalsLine2End)}
            </div>
          </>
        ),
      });
    }

    return tempAlerts;
  };

  const alerts = getAlerts();
  return {
    alerts,
  };
};
