import React from 'react';
import ReactDOM from 'react-dom';
// the scss shall be imported before app routes
// this ensures that the custom styles of the
// components / imported via AppRoutes
// come after the generic antD definitions
import './styles/main.scss';
import AppRoutes from 'AppRoutes';
import 'core-js/stable';
import * as serviceWorker from 'services/serviceWorker';
import tracking from 'services/tracking';


// IMPORTANT
// sets Monday as the first day of week in date pickers
import 'moment/locale/de';
tracking.registerSentry();


ReactDOM.render(<AppRoutes />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({});
tracking.registerGoogleTagManager();
