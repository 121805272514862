import { useGlobalSearch } from 'components/Header/components/SearchBar/services/useGlobalSearch';
import { LanguageContext } from 'contexts/LanguageContext';
import useSmartTable from 'elements/SmartTable/useSmartTable';
import { useContext, useMemo } from 'react';
import { useHistory } from 'react-router';
import useSiteMap from 'services/useSiteMap/useSiteMap';
import MainPageHeader from 'storybook-components/headers/MainPageHeader/MainPageHeader';
import { useWatermarks } from 'pages/Watermarks/services/useWatermarks';
import { Watermark } from 'data/watermark';
import SmartTable from 'elements/SmartTable/SmartTable';
import { Label } from 'storybook-components/Label';
import { Color } from 'theming';
import { Action } from 'elements/SmartTable/data';
import Button from 'elements/Buttons/Button/Button';
import EmptyTableMultilineMessage from 'storybook-components/table/EmptyTableMultilineMessage/EmptyTableMultilineMessage';
import { ICONS } from 'components/icons';
import { useWatermarksListColumns } from './services/useWatermarksListColumns';
import { translations } from './translations';

export default function WatermarksList(): JSX.Element {
  const { tl } = useContext(LanguageContext);
  const history = useHistory();
  const { watermarks, onDeleteWatermark } = useWatermarks();

  useGlobalSearch({
    key: 'watermarks',
  });

  const toTableData = (data: Watermark) => ({
    ...data,
  });

  const actionsMenu: Action[] = [
    {
      label: tl(translations.list.actions.create),
      onAction: () => {
        history.push('/letters/create');
      },
    },
    {
      label: tl(translations.list.actions.edit),
      onAction: (record: Watermark) => {
        history.push(`/watermarks/edit/${record.id}`);
      },
    },
    {
      label: <Label
        label={tl(translations.list.actions.delete)}
        testId="watermarks-list-item-delete"
        customTheme={{
          color: Color.Red,
        }}
      />,
      onAction: (record: Watermark) => {
        onDeleteWatermark(record.id);
      },
    },
  ];

  const dataSource = useMemo(() => watermarks.data.map(toTableData), [watermarks]);

  const smartTable = useSmartTable({
    tableName: 'watermarksList',
    columns: useWatermarksListColumns(),
    dataSource,
    contentLoading: watermarks.loading,
    actionsMenu,
    rowKey: 'id',
    onRow: (record: Watermark) => ({
      onClick: () => {
        history.push(`/watermarks/edit/${record.id}`);
      },
    }),
    emptyResultsComponent: <EmptyTableMultilineMessage
      title={tl(translations.list.emptyList.noWatermarkFiles)}
      description={tl(translations.list.emptyList.pleaseUploadWatermark)}
      icon={ICONS.file}
    />,
  });

  const { subcategorySwitcherItems } = useSiteMap();
  const subcategorySwitcherProps = {
    selectedKey: 'watermarks',
    navItems: subcategorySwitcherItems.settings,
  };

  return (
    <div className="Watermarks page">
      <MainPageHeader
        subcategorySwitcherProps={subcategorySwitcherProps}
        rightSideComponent={(
          <Button
            type="primary"
            onClick={
              () => history.push('/watermarks/create')
            }
          >
            {tl(translations.create)}
          </Button>
        )}
      />

      <SmartTable {...smartTable} />
    </div>
  );
}
