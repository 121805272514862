import {
  useContext,
} from 'react';

import {
  FinApiUserControllerApi,
} from 'api/accounting';
import { AuthContext } from 'contexts/AuthContext';
import { getEnvVar } from 'lib/getEnvVar';

export interface FinApiBankAccount {
  iban: string,
  accountName: string,
  accountNumber: string,
  accountHolderName: string,
  balance: string,
  overdraft: string,
  overdraftLimit: string,
  availableFunds: string,
}

export const useFinApiBankAccounts = () => {
  const { apiConfiguration } = useContext(AuthContext);
  const finApiUserControllerApi = new FinApiUserControllerApi(apiConfiguration('accounting'));

  const finapiBaseURL = getEnvVar('finapi.apiBaseUrl', 'https://mocks.app.impower.de/services/finapi');

  const buildFetchOptions = (token: string) => {
    const fetchOptions: any = {
      method: 'GET',
      mode: 'cors',
      redirect: 'follow',
      referrer: 'no-referrer',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };

    return fetchOptions;
  };
  const getBankAccounts = async (connectionId:number):Promise<FinApiBankAccount[]> => {
    const { token: finApiToken } = await finApiUserControllerApi.authenticateUsingPOST1({});
    let accountsResponseJson;
    const accounts = [];
    do {
      const accountsResponse = await fetch(`${finapiBaseURL}/api/v1/accounts?perPage=500?connectionId${connectionId}`, buildFetchOptions(finApiToken));
      accountsResponseJson = await accountsResponse.json();
      accounts.push(...accountsResponseJson.accounts);
    } while (accountsResponseJson.length === 500);
    return accounts;
  };
  return {
    getBankAccounts,
  };
};
