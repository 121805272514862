import { LanguageContext } from 'contexts/LanguageContext';
import { WATERMARKS_NAME_COLUMN_WIDTH } from 'pages/Watermarks/constants';
import { useContext } from 'react';
import { translations } from '../translations';

export const useWatermarksListColumns = () => {
  const { tl } = useContext(LanguageContext);

  return [
    {
      title: tl(translations.list.header.name),
      defaultWidth: WATERMARKS_NAME_COLUMN_WIDTH,
      dataKey: 'name',
    },
  ];
};
