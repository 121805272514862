import React, { useContext } from 'react';
import _ from 'lodash';
import useWatermarkOptions from 'services/useWatermarkOptions';
import { OwnersMeetingInvitationContext } from 'contexts/OwnersMeetingInvitationContext';
import { LanguageContext } from 'contexts/LanguageContext';
import SelectInput from 'elements/Inputs/SelectInput/SelectInput';
import TextInput from 'elements/Inputs/TextInput/TextInput';
import useRichTextEditor from 'elements/Inputs/RichTextEditor/useRichTextEditor';
import RichTextEditor from 'elements/Inputs/RichTextEditor/RichTextEditor';
import './CoverLetterSubsection.scss';
import { useLocation } from 'react-router';
import usePropertyManagementCompanyEmployeeOptions from 'services/usePropertyManagementCompanyEmployeeOptions';
import { ownersMeetingInvitationTranslations } from '../../../../translations/OwnersMeetingInvitationTranslations';
import useCoverLetterTemplates from './useCoverLetterTemplates';

export default function useCoverLetterSubsection({ setDirty }: any) {
  const { tl } = useContext(LanguageContext);
  const {
    coverLetterTemplateOptions, selectedCoverLetterTemplateName, setSelectedCoverLetterTemplateName, subject, body, onChangeSubject, onChangeBody,
  } = useCoverLetterTemplates({ setDirty });
  const location = useLocation();
  const viewMode = location.pathname.split('/')[3] === 'view';

  const richTextEditor = useRichTextEditor({ onChange: onChangeBody, value: body });
  const { watermarkOptions } = useWatermarkOptions();
  const { ownersMeeting, updateOwnersMeeting } = useContext(OwnersMeetingInvitationContext);

  const { managementCompanyEmployees } = usePropertyManagementCompanyEmployeeOptions();

  return [{
    sectionId: 'template',
    title: '',
    content: (
      <div className="cover-letter-subsection">
        <div className="template-selector cover-letter-subsection-element">
          <SelectInput
            label={tl(ownersMeetingInvitationTranslations.editPage.sections.shippingSection.template.fields.letter)}
            onChange={(value: string) => setSelectedCoverLetterTemplateName(value)}
            value={selectedCoverLetterTemplateName}
            options={coverLetterTemplateOptions}
            disabled={viewMode}
          />
        </div>
        <TextInput
          className="cover-letter-subsection-element"
          label={tl(ownersMeetingInvitationTranslations.editPage.sections.shippingSection.template.fields.subject)}
          onChange={onChangeSubject}
          value={subject}
          disabled={viewMode}
        />
        <RichTextEditor
          {...richTextEditor}
          buttons={'source,|,bold,strikethrough,underline,italic,eraser,|,ul,ol,|,'
              + 'outdent,indent,|,align,undo,redo,|,paste,symbol'}
          className="cover-letter-subsection-element"
          label={tl(ownersMeetingInvitationTranslations.editPage.sections.shippingSection.template.fields.body)}
          disabled={viewMode}
        />
        <SelectInput
          className="cover-letter-subsection-element"
          onChange={(value) => {
            updateOwnersMeeting({
              ..._.cloneDeep(ownersMeeting.data),
              watermarkId: value,
            });
            setDirty(true);
          }}
          value={ownersMeeting.data.watermarkId}
          options={watermarkOptions}
          placeholder={tl(ownersMeetingInvitationTranslations.editPage.sections.shippingSection.noWatermark)}
          label={tl(ownersMeetingInvitationTranslations.editPage.sections.shippingSection.template.fields.watermark)}
          allowClear
          disabled={viewMode}
        />
        <SelectInput
          className="cover-letter-subsection-element"
          onChange={(value) => {
            updateOwnersMeeting({
              ..._.cloneDeep(ownersMeeting.data),
              signingPersonId: value,
            });
            setDirty(true);
          }}
          showSearch
          value={ownersMeeting.data.signingPersonId}
          options={managementCompanyEmployees}
          label={tl(ownersMeetingInvitationTranslations.editPage.sections.appointmentSection.fields.signingPerson)}
          allowClear
          disabled={viewMode}
        />
      </div>),
  }];
}
