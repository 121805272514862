import Button from 'elements/Buttons/Button/Button';
import React, { useRef } from 'react';
import Icon, { SyncOutlined } from '@ant-design/icons';
import { ICONS } from 'components/icons';
import { Tag } from 'antd';
import { LoadingScreen } from 'storybook-components/feedback/LoadingScreen/LoadingScreen';
import { PagesOrientationType } from 'pages/PdfPageAssigner/services/interfaces';
import { usePdfViewer, UsePdfViewerArgs } from './services/usePdfViewer';
import PdfPageWrapper from './components/PdfPageWrapper';
import VirtualizePdfPageWrapper from './components/VirtualizePdfPageWrapper';
import { useSimplePdfViewer } from './services/useSimplePdfViewer';
import PdfPreview from './components/PdfPreview';
import './PdfViewer.scss';
import { PdfPageWrapperProps } from './services/interfaces';
import { usePdfWidthAndZooming } from './services/usePdfWidthAndZooming';


export const PDF_VIEWER_ROOT_NODE_ID = 'pdf-virtual-pages-scrollable-wrapper';

const PDF_LIST_VIEW_DEFAULT_WIDTH = 850;

const PDF_GRID_VIEW_DEFAULT_WIDTH = 292;


export interface SimplePdfViewerProps extends Pick<UsePdfViewerArgs, 'url' | 'base64Url' | 'documentId' | 'filename'> {
  virtualize?: boolean,
  gridView?: boolean,
  renderTextLayer?: boolean,
  showOcrInProgress?: boolean,
  ocrText?: string,
  pageComponent?: (prps: PdfPageWrapperProps) => JSX.Element,
  suffixComponent?: React.ReactNode,
  hideButtons?: {
    zoomIn?: boolean,
    zoomOut?: boolean,
    download?: boolean,
  },
  customOnLoadCatch?: () => void,
}


const SimplePdfViewer = ({
  url,
  base64Url,
  documentId,
  filename,
  virtualize,
  gridView,
  renderTextLayer,
  showOcrInProgress,
  ocrText,
  pageComponent: PageComponentProp,
  suffixComponent,
  hideButtons,
  // see explanation in usePdfViewer.ts
  customOnLoadCatch,
}: SimplePdfViewerProps) => {
  const { pdf, onDownloadPdf } = usePdfViewer({
    url, base64Url, filename, documentId, customOnLoadCatch,
  });


  const pdfContainerRef: any = useRef(null);


  const { pages, scrollToPage, pdfViewerId } = useSimplePdfViewer(pdf);
  const {
    pdfPreviewWidth, onChangeZoom, zoomInDisabled, zoomOutDisabled,
  } = usePdfWidthAndZooming(PDF_LIST_VIEW_DEFAULT_WIDTH);


  const PageComponent = PageComponentProp ?? (virtualize ? VirtualizePdfPageWrapper : PdfPageWrapper);

  if (!pdf) {
    return (
      <div className="PdfViewer">
        <LoadingScreen />
      </div>
    );
  }


  if (gridView) {
    return (
      <div className="PdfViewer">
        <div id={PDF_VIEWER_ROOT_NODE_ID} className="pdf-grid-wrapper" ref={pdfContainerRef}>
          <div className="pdf-wrapper">
            {pages.map((page: any) => (
              <PageComponent
                page={page}
                key={pdf.fingerprints[0] + page._pageIndex}
                pdfPreviewWidth={PDF_GRID_VIEW_DEFAULT_WIDTH}
                parentWidth={PDF_GRID_VIEW_DEFAULT_WIDTH}
                id={`${pdfViewerId}-page-${page._pageIndex}`}
                selectableText={false}
                rootNodeId={PDF_VIEWER_ROOT_NODE_ID}
                pageNumber={page._pageIndex}
                // no text layer for grid view
                renderTextLayer={false}
                viewMode={PagesOrientationType.GRID}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }


  return (
    <div className="PdfViewer">
      <PdfPreview
        pdf={pdf}
        pages={pages}
        scrollToPage={scrollToPage}
        virtualize={virtualize}
      />
      <div
        id={PDF_VIEWER_ROOT_NODE_ID}
        className="pdf-container"
        ref={pdfContainerRef}
      >
        <div className="pdf-wrapper">
          {(pdf && showOcrInProgress) && (
            <Tag className="analyzing">
              <SyncOutlined spin />
              <span>{ocrText}</span>
            </Tag>
          )}
          {pages.map((page: any) => (
            <PageComponent
              page={page}
              key={pdf.fingerprints[0] + page._pageIndex}
              pdfPreviewWidth={pdfPreviewWidth}
              parentWidth={pdfContainerRef.current?.getBoundingClientRect().width - 204 /* - (preview width + button bar width) */}
              selectableText
              id={`${pdfViewerId}-page-${(page._pageIndex)}`}
              renderTextLayer={renderTextLayer}
              rootNodeId={PDF_VIEWER_ROOT_NODE_ID}
              pageNumber={page._pageIndex}
              viewMode={PagesOrientationType.LIST}
            />
          ))}
        </div>
      </div>


      <div className="buttons">
        {!hideButtons?.download && (
          <Button
            onClick={onDownloadPdf}
            className="download-button"
          >
            <Icon component={ICONS.download} />
          </Button>
        )}
        {!hideButtons?.zoomIn && (
          <Button
            className="zoom-btn zoom-in-btn"
            disabled={zoomInDisabled}
            onClick={() => onChangeZoom('zoomIn')}
          >
            <Icon component={ICONS.plusNew} />
          </Button>
        )}
        {!hideButtons?.zoomOut && (
          <Button
            className="zoom-btn zoom-out-btn"
            disabled={zoomOutDisabled}
            onClick={() => onChangeZoom('zoomOut')}
          >
            <Icon component={ICONS.minusNew} />
          </Button>
        )}
      </div>

      {suffixComponent && (
        <div className="suffix-component">
          { suffixComponent}
        </div>
      )}
    </div>
  );
};

SimplePdfViewer.defaultProps = {
  virtualize: false,
  gridView: false,
  renderTextLayer: false,
  showOcrInProgress: false,
  ocrText: '',
  pageComponent: undefined,
  suffixComponent: undefined,
  hideButtons: undefined,
  customOnLoadCatch: undefined,
};


export default SimplePdfViewer;
