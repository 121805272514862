import { RouteDefinition } from 'components/RenderRoutes';
import { DOCUMENT_PREVIEW_PATH } from 'pages/DocumentPreview/routes';
import { MESSAGE_SENDING_PATH } from 'pages/MessageSendingPage/routes';
import { PDF_DOCUMENT_ASSIGNER_COMPONENT, PDF_DOCUMENT_ASSIGNER_PATH } from 'pages/PdfPageAssigner/routes';
import { lazy } from 'react';
import { WkaList } from './list/WkaList';

const WkaEditor = lazy(() => import('./WkaEditor/WkaEditor'));
const MessageSendingPageWithProvider = lazy(() => import('pages/MessageSendingPage/MessageSendingPage'));
const DocumentPreviewPage = lazy(() => import('pages/DocumentPreview/DocumentPreviewPage'));

export const WKA_EDITOR_COMPONENT = WkaEditor;
export const WKA_CREATE_PATH = '/wka/create';
export const WKA_EDIT_PATH = '/wka/edit/:wkaId';

export const getWkaEditingRoute = (wkaId: number) => WKA_EDIT_PATH.replace(':wkaId', wkaId?.toString());
export const getWkaSendingRoute = (wkaId: number) => `${WKA_EDIT_PATH}${MESSAGE_SENDING_PATH}`.replace(':wkaId', wkaId?.toString());

export const WkaRoutes: RouteDefinition = {
  subRoutes: [
    {
      path: '/wka',
      component: WkaList,
      subRoutes: [
        {
          path: WKA_CREATE_PATH,
          component: WKA_EDITOR_COMPONENT,
          type: 'focus',
        },
        {
          path: WKA_EDIT_PATH,
          component: WKA_EDITOR_COMPONENT,
          type: 'focus',
          subRoutes: [
            {
              path: `${WKA_EDIT_PATH}/${PDF_DOCUMENT_ASSIGNER_PATH}`,
              component: PDF_DOCUMENT_ASSIGNER_COMPONENT,
              type: 'overlay',
            },
            {
              path: `${WKA_EDIT_PATH}${MESSAGE_SENDING_PATH}`,
              component: MessageSendingPageWithProvider,
              type: 'overlay',
              subRoutes: [
                {
                  path: `${WKA_EDIT_PATH}${MESSAGE_SENDING_PATH}${DOCUMENT_PREVIEW_PATH}`,
                  component: DocumentPreviewPage,
                  type: 'overlay',
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};
