import './SecondarySectionWrapper.scss';

import React, { useState } from 'react';

import { ICONS } from 'components/icons';
import Button from 'elements/Buttons/Button/Button';
import { InfoIcon } from 'storybook-components/InfoIcon/InfoIcon';
import Heading from 'storybook-components/typography/Heading/Heading';

import Icon from '@ant-design/icons/lib/components/Icon';

interface Props {
  icon?: React.ReactNode,
  title?: string,
  subSectionSuffix?: React.ReactNode,
  subSectionOpenSuffix?: React.ReactNode,
  subSectionClosedSuffix?: React.ReactNode,
  children?: React.ReactNode,
  className?: string,
  infoIconText?: string,
  collapseEnabled?: boolean,
  // defaultCollapsed is used to initialize the collapsed value.
  // If defaultCollapsed changes this component does not react to that change
  // if you want to control the collapsed state from outside than use isCollapsed instead
  defaultCollapsed?: boolean,
  isCollapsed?: boolean,
}

const SecondarySectionWrapper = ({
  icon,
  title,
  subSectionSuffix,
  subSectionOpenSuffix,
  subSectionClosedSuffix,
  children,
  className,
  infoIconText,
  collapseEnabled,
  defaultCollapsed,
  isCollapsed: isCollapsedProp,
}: Props) => {
  const [isCollapsedInner, setIsCollapsedInner] = useState<boolean>(defaultCollapsed);

  const onClickToggleCollapse = () => {
    setIsCollapsedInner(prev => !prev);
  };

  const isCollapsed = isCollapsedProp ?? isCollapsedInner;

  let suffix;
  if (subSectionSuffix !== undefined) {
    suffix = subSectionSuffix;
  } else if (subSectionOpenSuffix && !isCollapsed) {
    suffix = subSectionOpenSuffix;
  } else if (subSectionClosedSuffix && isCollapsed) {
    suffix = subSectionClosedSuffix;
  }

  return (
    <div className={`SecondarySectionWrapper ${className ?? ''}`}>
      <Heading level={3} className={`subsection-title-wrapper ${title === undefined ? 'no-title' : ''}`}>
        {icon && <span className="icon">{icon}</span>}
        {title && (
          <span className="title">
            {title}
            {infoIconText ? (<InfoIcon popupText={infoIconText} placement="top" />) : ''}
            {collapseEnabled
            && (
              <Button
                className={`collapse-button ${isCollapsed ? 'point-down' : 'point-up'}`}
                icon={<Icon component={ICONS.triangleDown} />}
                onClick={onClickToggleCollapse}
              />
            )}
          </span>
        )}
        {suffix}
      </Heading>

      {!isCollapsed
        && (
          <div className="subsection-content">
            {children}
          </div>
        )}
    </div>
  );
};

export default SecondarySectionWrapper;

SecondarySectionWrapper.defaultProps = {
  title: undefined,
  icon: undefined,
  subSectionSuffix: undefined,
  subSectionOpenSuffix: undefined,
  subSectionClosedSuffix: undefined,
  children: undefined,
  className: undefined,
  infoIconText: undefined,
  collapseEnabled: false,
  defaultCollapsed: false,
  isCollapsed: undefined,
};
